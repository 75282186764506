import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customerList, agentList } from '@/api/ceshi_xiugai2/myproject';
import Head from '@/components/head/index';
import TimeScreeningse from '@/components/timeScreeningse';
export default {
  name: 'freeRegistration',
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse
  },
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      listLoading: false,
      //加载动画
      title: '商城免费报名客户',
      ClassSearch: '',
      //搜索
      timer: '',
      //时间
      page: 1,
      //页码
      pagesize: 10,
      //条数
      start_time: '',
      //开始时间
      end_time: '',
      //结束时间
      key_word: '',
      //客户电话、客户名称模糊搜索
      total: 0,
      //总数
      List_page: 1,
      List_pageSize: 15,
      key_wordS: '',
      enterprise: [],
      value: '',
      //企业名称绑定值
      tableData: [],
      pickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList(this.page, this.pagesize);
    } else {}
  },
  methods: {
    GetList: function GetList(page, pagesize) {
      var _this = this;
      customerList({
        page: String(page),
        pagesize: String(pagesize),
        start_time: String(this.start_time),
        end_time: String(this.end_time),
        key_word: this.key_word,
        enterprise_id: String(this.key_wordS)
      }).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      });
    },
    // 获取焦点企业名称
    focusPrise: function focusPrise() {
      var _this2 = this;
      agentList({
        page: this.List_page,
        pagesize: this.List_pageSize
      }).then(function (res) {
        _this2.enterprise = res.data.data;
      });
    },
    // 失去焦点企业名称
    blurPrise: function blurPrise() {
      this.List_page = 1;
    },
    // 分页企业名称
    enterprisePage: function enterprisePage() {
      var _this3 = this;
      if (this.key_wordS == '') {
        this.List_page++;
        agentList({
          page: this.List_page,
          pagesize: this.List_pageSize
        }).then(function (res) {
          if (res.data.data != '') {
            res.data.data.forEach(function (element) {
              _this3.enterprise.push(element);
            });
          }
        });
      }
    },
    // 搜索企业名称
    methodPrise: function methodPrise(val) {
      var _this4 = this;
      this.key_wordS = val;
      this.List_page = 1;
      this.enterprise = [];
      agentList({
        page: this.List_page,
        pagesize: 100,
        key_word: this.key_wordS
      }).then(function (res) {
        if (res.data.data != '') {
          _this4.enterprise = res.data.data;
        }
      });
    },
    // 搜索
    ClassSearchInput: function ClassSearchInput() {
      this.page = 1;
      this.GetList(this.page, this.pagesize);
    },
    // 选定时间
    lastUpdateClick: function lastUpdateClick() {
      this.page = 1;
      if (this.timer == null) {
        this.start_time = '';
        this.end_time = '';
        this.GetList(this.page, this.pagesize);
      } else {
        this.start_time = this.timer[0].toString().substring(0, 10);
        this.end_time = this.timer[1].toString().substring(0, 10);
        this.GetList(this.page, this.pagesize);
      }
    },
    // 选择企业名称
    company: function company(val) {
      this.GetList(this.page, this.pagesize);
    },
    // 更换条数
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.pagesize = val;
    },
    // 更换页码
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.page = val;
    }
  }
};